import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class CandidateService
    {
        #api = null;    
        constructor() {
            this.#api = API_URL + 'admin/candidate';
        }
        paginate(data={},index = null) {
            let url = `${this.#api}`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        getUnscheduledCandidates(data={},index = null) {
            let url = `${this.#api}-unscheduled`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        getCandidateSummary(examKey){
            let url = `${this.#api}/exam-key/${examKey}`
            return apiService.get(url)
        }

        update(id,data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url,data)
        }

        updateExamPin(id) {
            let url = `${this.#api}/${id}/change-exam-pin`
            return apiService.put(url)
        }

        resendPurchaseDetail(id) {
            let url = `${this.#api}/${id}/resend-purchase-details`
            return apiService.get(url)
        }

        resendCandidateDetail(id) {
            let url = `${this.#api}/${id}/resend-candidate-detail`
            return apiService.get(url)
        }

        getLogs(data, id, index = null){
            let url = `${this.#api}/${id}/get-logs`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);
        }

        // exports
        exportToCsv(data = {}){
            let url = `${this.#api}/export/csv`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }

        exportPracticalCandidateToCsv(data = {}){
            let url = `${this.#api}-practical/export/csv`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }

        exportOnlineExamCandidateToCsv(data = {}){
            let url = `${this.#api}-online-exam/export/csv`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }

        exportOnlineExamCandidateToExcel(data = {}){
            let url = `${this.#api}-online-exam/export/excel`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }

        exportPracticalCandidateToExcel(data = {}){
            let url = `${this.#api}-practical/export/excel`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }
        
        exportToExcel(data = {}){
            let url = `${this.#api}/export/excel`;
            if (data != null && data != "") {
                let queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                }).join('&');
                url = url + '?' + queryString;
            }
            return apiService.get(url);
        }

        getExportFilters(){
            let url = `${this.#api}/get-search-parameters`;
            return apiService.get(url);
        }

        getPracticalCandidateExportFilters(){
            let url = `${this.#api}-practical/get-search-parameters`;
            return apiService.get(url);
        }

        getOnlineExamCandidateExportFilters(){
            let url = `${this.#api}-online-exam/get-search-parameters`;
            return apiService.get(url);
        }

        transferAccountHolder(id,data)
        {
            let url = `${this.#api}/transfer-accountHolder/${id}`
            return apiService.post(url,data)
        }
        transferMultipleForAccountHolder(data)
        {
            let url = `${this.#api}/transfer-multiple-accountHolder`;
            return apiService.post(url,data)
        }

        getAllCandidate(data) {
            let url = `${this.#api}/get/all?name=${data}`;
            return apiService.query(url);
        }

        getAllCandidateByOwner(ownerId, data) {
            let url = `${this.#api}/${ownerId}/get/all/owner?name=${data}`;
            return apiService.query(url);
        }
        getAllAccountHolderCandidates(userId,data){
            let url = `${this.#api}/${userId}/get/all/user`;
            let param = {params: data};
            return apiService.query(url, param);
        }

        viewNotificationLetter(examDayId, accountHolderId)
        {
            let url = `${this.#api}/account-holder-postal-view-pdf/${examDayId}/${accountHolderId}`
            window.open(url, "_blank");
        }

        changeAccountHolderResultAvailability(candidateId, data = {}){
            let url = `${this.#api}/${candidateId}/change/availability-to-accountholder`;
            return apiService.post(url, data);
        }

        changeCandidateResultAvailability(candidateId, data = {}){
            let url = `${this.#api}/${candidateId}/change/availability-to-candidate`;
            return apiService.post(url, data);
        }
        show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url)
        }
        markAsDispatched(id,data)
        {
            let url = `${this.#api}/${id}/set-dispatched-date`
            return apiService.post(url,data)
        }

        markAsDispatched(data){
            let url = `${this.#api}/mark-as-dispatched`;
            return apiService.post(url, data);
        }
        findByExamKey(data={}){
            let url = `${this.#api}/find/by/exam-key`;
            let param = {params: data};
            return apiService.query(url, param);
        }
        getAllClassExam(data={}){
            let url = `${this.#api}/get/all/class-exams`;
            let param = {params: data};
            return apiService.query(url, param);
        }
        updateExamEnroled(id,data){
            let url = `${this.#api}/${id}/update-exam-enroled`;
            return apiService.post(url,data)
        }
        searchAll(data, index = null){
            let url = `${this.#api}/search/all`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param = {params: data};
            return apiService.query(url, param);
        }

        markCertificatePrintedDispatched(data){
            let url = `${this.#api}/update/certificate/print-dispatched`;
            return apiService.post(url, data);
        }
        
        markCandidateAsNoShown(candidateId){
            let url = `${this.#api}/${candidateId}/mark-as-no-shown`;
            return apiService.post(url);
        }
    
        markCandidateAsWithDrawn(candidateId){
            let url = `${this.#api}/${candidateId}/mark-as-withdrawn`;
            return apiService.post(url);
        }
    
        markCandidateAsCompleted(candidateId){
            let url = `${this.#api}/${candidateId}/mark-as-completed`;
            return apiService.post(url);
        }

        makeResultAvailableToEnroler(data){
            let url = `${this.#api}/make/result-available-to-enroler`;
            return apiService.post(url, data);
        }

        makeMultipleResultUnavailableToEnroler(data){
            let url = `${this.#api}/make/multiple-result-unavailable-to-enroler`;
            return apiService.post(url, data);
        }

        makeResultUnAvailableToEnroler(candidateId){
            let url = `${this.#api}/${candidateId}/make/result-unavailable-to-enroler`;
            return apiService.post(url);
        }

        markMultipleAsNoShow(data){
            let url = `${this.#api}/mark-multiple-as-no-shown`;
            return apiService.post(url, data);
        }

        markMultipleAsWithdrawn(data){
            let url = `${this.#api}/mark-multiple-as-withdrawn`;
            return apiService.post(url, data);
        }

        markMultipleAsCompleted(data){
            let url = `${this.#api}/mark-multiple-as-completed`;
            return apiService.post(url, data);
        }
        markMultipleAsAssessed(data){
            let url = `${this.#api}/mark-multiple-as-assessed`;
            return apiService.post(url, data);
        }

        getByIds(data={})
        {
            let url=`${this.#api}/get/candidates-by-ids`;
            let param ={
                params: data
            }
            return apiService.query(url,param)
        }
        getCompleteSearchUrl(data={},index = null) {
            let url = this.#api;
            return url;
        }

        changeToIndividualExam(data) {
            let url = `${this.#api}/change/individual-exam`;
            return apiService.post(url, data);
        }

        deleteTransaction(cartId) {
            let url = `${this.#api}/${cartId}/delete-transaction`;
            return apiService.post(url);
        }

        previewPplateCertificate(id){
            let url = `${this.#api}/print-certificate/pplate/${id}`
            window.open(url, "_blank");
        }

        previewCertificatePdf(resultId){
            let url = `${this.#api}/ameb-award/${resultId}/certificate`
            return window.open(url, '_blank');
        }

        getByUser(data={}, index, userId) {
            let url = `${this.#api}/user/${userId}/get-by-user`;
            if (index != null)
                url = `${url}?page=${index}`;
            let param ={
                params: data
            }
            return apiService.query(url,param);            
        }

        changeUser(id,data) {
            let url = `${this.#api}/change-user/${id}`
            return apiService.put(url,data)
        }
    }
