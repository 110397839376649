import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidatePlayListService
{
    #api = null;    
    
    constructor() {
        this.#api = API_URL + 'admin/candidate';
    }
    
    paginate(data={},index = null) {
        let url = `${this.#api}-playlist`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getByExamKey(examKey){
        let url = `${this.#api}-playlist/${examKey}`;
        return apiService.get(url);
    }

    viewPlaylistPdf(candidatePlayListId){
        let url = `${this.#api}/playlist/${candidatePlayListId}`;
        window.open(url, '_blank');
    }

    create(data) {
        let url = `${this.#api}-playlist`;
        return apiService.post(url, data);
    }

    sendRepertoireMail(examKey){
        let url = `${this.#api}/playlist/${examKey}/send-email`;
        return apiService.get(url);
    }

    saveApraDeclaration(candidatePlayListId, data){
        let url = `${this.#api}/playlist/upload/apra-declaration/${candidatePlayListId}`
        return apiService.post(url, data)
    }
}