<template>
    <v-app>
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="card-title pt-3 d-flex justify-content-between">
                        <div class="breadcrumb-left">
                            <h4>
                            Candidate repertoire submission
                            </h4>
                            <div class="breadcrumb-sub-header">
                            <router-link to="/dashboard">Dashboard </router-link>\ Candidate repertoire submission
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 pb-10">
                        <div class="d-flex justify-space-between p-2 px-3 mb-5" style="background: #ececec; border: 0px;">
                            <div>
                                <div class="font-size-lg"> <strong> Candidate name : </strong> {{candidate_summary ? candidate_summary.full_name : ''}} </div>
                                <div class="font-size-lg"> <strong> Enrolment key : </strong> {{examKey}} </div>
                                <div class="font-size-lg"> <strong> Exam : </strong> {{candidate_summary ? candidate_summary.exam_name : ''}} </div>
                            </div>
                        </div>
                        <v-row>
                            <v-col cols="12">

                                <v-alert
                                    v-if="hasCandidatePlaylist"
                                    icon="fas fa-square-check"
                                    prominent
                                    text
                                    class="my-4"
                                    color="green lighten"
                                >
                                    Repertoire information for this candidate has been submitted. Any changes or submission made later than 72hrs prior to the exam may not be received by the examiner.
                                </v-alert>
                                <div v-if="hasCandidatePlaylist" class="">
                                    <v-btn
                                        small
                                        depressed
                                        @click="viewPlaylistPdf"
                                        color="red lighten-1"
                                        class="text-white"
                                    >
                                        <i class="fas fa-file-pdf" style="color:#fff;"></i> &nbsp;View PDF
                                    </v-btn>
                                    <v-btn
                                        small
                                        depressed
                                        color="blue"
                                        @click="editCandidatePlaylist"
                                        class="text-white ml-1"
                                    >
                                        <i class="fas fa-edit" style="color:#fff;" ></i> &nbsp;Edit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <p class="mt-2 mb-0" v-if="showApraField && showPlaylistForm">
                                    If you are providing copies of your music to the Examiner, or needing to acknowledge copyright for your performance works, you may need to provide the
                                    <strong>
                                        Copyright Declaration Form,
                                    </strong> as provided by <a href="https://onemusic.com.au/" target="_blank"> OneMusic </a>/APRA. You can download the form
                                    <a href="https://onemusic.com.au/media/Information-Sheets/Copyright-Declaration-Form.pdf" target="_blank">here</a> and bring it with you on the day, or provide additional details with your repertoire list, below.

                                    <br>


                                    <v-checkbox v-model="candidate_playlist.apra_include_copyright_declaration" label="Include a Copyright Declaration with my repertoire list"></v-checkbox>
                                </p>
                            </v-col>
                        </v-row>

                        <v-row
                            v-if="showPlaylistForm" 
                            v-for="(row, index) in fields" 
                            :key="index" 
                            class="mb-3 rounded bg-yellow px-6 mx-1"
                        >
                            <v-col cols="12" md="6">
                                <label for="#">Piece Type <span class="text-danger">*</span> </label>
                                <v-select
                                    outlined
                                    dense
                                    v-model="row.type"
                                    :items="temp_types"
                                    @change="getTypes"
                                    label="Piece type"
                                    item-text="name"
                                    item-value="value"
                                ></v-select>
                                <span class="text-danger" v-if="$v.fields.$each[index].type.$error">This information is required</span>

                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="#">Composer <span class="text-danger">*</span> </label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="row.composer"
                                    label="Composer"
                                ></v-text-field>
                                <span class="text-danger" v-if="$v.fields.$each[index].composer.$error">This information is required</span>

                            </v-col>
                            <v-col cols="12" md="6">
                                <label for="#">Piece <span class="text-danger">*</span> </label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="row.title_of_piece"
                                    label="Piece"
                                ></v-text-field>
                                <span class="text-danger" v-if="$v.fields.$each[index].composer.$error">This information is required</span>
                            </v-col>

                            <v-col v-if="requiresApraDeclaration && candidate_playlist.apra_include_copyright_declaration" cols="12" md="6">
                                <label for="#">Publisher <span class="text-danger">*</span> </label>
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="row.publisher"
                                >
                                    <template v-slot:label>
                                        Publisher <span class="text-danger">*</span>
                                    </template>
                                </v-text-field>
                                <span class="text-danger" v-if="$v.fields.$each[index].publisher.$error">This information is required</span>
                            </v-col>

                            <v-col cols="12">
                                <v-btn
                                    v-if="index || (!index && fields.length > 1)"
                                    @click="removeFieldColumn(index)"
                                    small
                                    color="red lighten-1"
                                    class="text-white mt-2 ml-3"
                                >
                                    <i class="fas fa-trash" style="color: #ffffff"></i>
                                </v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-row v-if="showPlaylistForm && candidate_summary.requires_aural_sight_reading">
                            <v-col cols="12" md="12">
                                <v-select   
                                    class="mt-4"
                                    :items="auralSightReadingValues"
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    v-model="candidate_playlist.auralOrSightReading"
                                    dense
                                    :error="$v.candidate_playlist.auralOrSightReading.$error"
                                >
                                    <template v-slot:label>
                                        Aural or sight-reading <span class="text-danger">*</span>
                                    </template>
                                </v-select>
                                <span class="text-danger" v-if="$v.candidate_playlist.auralOrSightReading.$error">This information is required</span>
                            </v-col>
                        </v-row>

                        <!-- <v-row>
                            <v-col cols="12">
                                <div>
                                    <a style="font-size: 17px; font-weight:bold;" href="https://onemusic.com.au/media/Information-Sheets/Copyright-Declaration-Form.pdf" target="_blank">
                                    APRA declaration 
                                    </a>
                                </div>
                                <div class="mt-4">
                                    <v-file-input
                                        outlined
                                        dense
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-file"
                                        v-model="apra_declaration.apra_declaration_file"
                                        label="Upload APRA Declaration - File"
                                    >
                                    </v-file-input>
                                </div>
                                <div class="mt-2" v-if="candidate_playlist.apra_declaration_file">
                                    <v-btn
                                        small
                                        depressed
                                        color="blue"
                                        class="text-white"
                                        @click="viewApraDeclaration(candidate_playlist.apra_declaration_file)" 
                                    >
                                        View APRA Declaration File
                                    </v-btn>
                                </div>   
                            </v-col>
                        </v-row> -->

                        <v-row>
                            <v-col cols="12">
                                <div v-if="!hasCandidatePlaylist" class="font-size-lg my-5 text-right">
                                    <v-btn  
                                        @click="addFieldColumn"
                                        x-large
                                        text
                                    ><i class="fa fa-plus"></i> Add another work</v-btn>
                                </div>
                                <div v-if="hasCandidatePlaylist && edit" class="font-size-lg my-5 text-right">
                                    <v-btn  
                                        @click="addFieldColumn"
                                        x-large
                                        text
                                    ><i class="fa fa-plus"></i> Add another work</v-btn>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row v-if="showApraField && candidate_playlist.apra_include_copyright_declaration && showPlaylistForm" class="mt-2">
                            <v-col cols="12">
                                <p>
                                    The following information is collected, as per the Copyright Declaration Form provided by <strong>OneMusic/APRA</strong>. Licensing enquiries should be directed to 1300 162 162 or <a href="mailto:welcome@onemusic.com.au"></a> welcome@onemusic.com.au.

                                    <br>Please complete all applicable sections.
                                </p>
                                <ul>
                                    <li class="list-none">
                                        <b>1. Performer's Declaration <span class="text-danger">*</span></b>
                                        <div class="text-danger" v-if="$v.candidate_playlist.apra_performers_declaration.$error">This field is required</div>
                                        <ul class="ml-10">
                                            <li class="list-none">
                                                I certify that I will be performing (choose one):
                                            </li>
                                            <li class="list-none">
                                                <v-checkbox
                                                    label="From an original published edition of the sheet music;"
                                                    v-model="candidate_playlist.apra_performers_declaration"
                                                    value="from_original_published_edition_of_sheet_music"
                                                ></v-checkbox>

                                            </li>
                                            <li class="list-none">
                                                <v-checkbox
                                                    label="From a photocopy, transcription or arrangement of sheet music that is accompanied by a letter from the music publisher authorising the use of this photocopy, transcription or arrangement (attached to this form);"
                                                    v-model="candidate_playlist.apra_performers_declaration"
                                                    value="from_photocopy_transcription_arrangement_of_sheet_music"
                                                ></v-checkbox>
                                            </li>
                                            <li class="list-none">
                                                <v-checkbox
                                                    label="From sheet music where both the work and edition are out of copyright*;or"
                                                    v-model="candidate_playlist.apra_performers_declaration"
                                                    value="from_sheet_music_where_both_work_edition_are_out_of_copyright"
                                                ></v-checkbox>
                                            </li>
                                            <li class="list-none">
                                                <v-checkbox
                                                    label="By ear"
                                                    v-model="candidate_playlist.apra_performers_declaration"
                                                    value="by_ear"
                                                ></v-checkbox>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="list-none">
                                        <b>
                                            2. Making a temporary copy for an examiner (if applicable)
                                        </b>
                                        <ul class="ml-10">
                                            <li class="list-none">
                                                <v-checkbox
                                                    v-model="candidate_playlist.apra_make_temporary_copy_for_examiner"
                                                    label="I certify that I have made a copy of the legal sheet music from which I will perform for use by an Examiner. I acknowledge that this copy will not be returned to me and will be
                                                    destroyed by the exam organisers and I must delete any digital copy of this sheet music I have made. **"
                                                ></v-checkbox>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="list-none">
                                        <div>
                                            <b>
                                                3. Making a temporary copy for an accompanist (if applicable)
                                            </b>
                                        </div>
                                        <ul class="ml-10">
                                            <v-checkbox
                                                v-model="candidate_playlist.apra_make_temporary_copy_for_accompanist"
                                                label="I certify that I have made a copy of the legal sheet music from which I will perform for the use of an accompanist. I acknowledge that this copy will be returned to me and I must destroy it immediately,
                                                and I must delete any digital copy of this sheet music I have made. **"
                                            ></v-checkbox>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    This form is for the event organisers only and is not to be returned to OneMusic.
                                    *Composer/lyricist/arranger ALL died before 1955, and the edition publication date was more than 25 years ago. <br>
                                    **Any user of copies made under this declaration outside of the performance in this specific exam is deemed an infringement. All copies – both digital and hardcopy – must be destroyed.
                                </p>

                                <strong class="text-h6">
                                    Summary
                                </strong>
                                <p>
                                    <b> Performer or ensemble name: </b> {{ candidate_summary.full_name }} <br>
                                    <b> Competition/Eisteddfod/Festival name: </b> {{ candidate_summary.product_name }} <br>
                                    <b> Signatory’s name: </b> {{ candidate_summary.owner.full_name }}  <br>
                                    <b> Date: </b> {{ edit ? candidate_playlist.formatted_created_date : currentDate }}
                                </p>
                                <v-checkbox
                                    v-model="candidate_playlist.apra_is_terms_conditions_agreed"
                                >
                                    <template
                                    v-slot:label>
                                    I, {{ candidate_summary.owner.full_name }}, acknowledge that I am 17 years or older, by submitting this form, I confirm that I understand my responsibilities with regard to copying music,  and that the details I have provided are accurate.
                                    <span class="text-danger">*</span>
                                    </template>
                                </v-checkbox>
                                <span class="text-danger" v-if="$v.candidate_playlist.apra_is_terms_conditions_agreed.$error">This field is required</span>
                                <span class="text-danger" v-if="!termsAgreed">This field is required</span>


                                <!-- <div class="mt-4">
                                    <v-file-input
                                        outlined
                                        dense
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-file"
                                        v-model="apra_declaration.apra_declaration_file"
                                        label="Upload APRA Declaration - File"
                                    >
                                    </v-file-input>

                                    <v-btn class="my-2" v-if="candidate_playlist.apra_declaration_file_path" @click="viewApraFile(candidate_playlist.apra_declaration_file_path)">
                                        View APRA Declaration file
                                    </v-btn>

                                    <span class="text-danger" v-if="$v.apra_declaration.apra_declaration_file.$error">This field is required</span>
                                </div> -->
                            </v-col>
                        </v-row>

                        <v-row v-if="showPlaylistForm">
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    @click="saveCandidatePlaylist"
                                    class="btn btn-primary text-white"
                                    large
                                    :loading="loading"
                                >Submit repertoire</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
import {required, requiredIf} from "vuelidate/lib/validators";

const candidate = new CandidateService();
const candidatePlayList = new CandidatePlayListService();

export default {
    validations:{
        fields: {
            $each: {
                type: {
                    required
                },
                composer: {
                    required
                },
                title_of_piece: {
                    required
                },
                publisher: {
                    required: requiredIf(function(){
                        return (this.requiresApraDeclaration && this.candidate_playlist.apra_include_copyright_declaration);
                    })
                },
            }
        },
        candidate_playlist:{
            auralOrSightReading: {
                required : requiredIf(function(){
                    return this.candidate_summary.requires_aural_sight_reading;
                })
            },
            apra_performers_declaration: {
                required: requiredIf(function(){
                    if(this.showApraField && this.candidate_playlist.apra_include_copyright_declaration){
                        return true;
                    }
                    return false;
                })
            },
            apra_is_terms_conditions_agreed:{
                required: requiredIf(function(){
                    if(this.showApraField && this.candidate_playlist.apra_include_copyright_declaration){
                        return true;
                    }
                    return false;
                })
            }
        }  
    },
    data(){
        return {
            examKey: '',
            candidate_summary: null,
            loading: false,
            fields:[{
                type:'',
                composer:'',
                publisher: '',
                title_of_piece:'',
            }],
            candidate_playlist:{
                type:[],
                composer:[],
                title_of_piece:[],
                exam_key: null,
                auralOrSightReading: '',
                // apra_declaration_file: ''
                apra_include_copyright_declaration: 0,
                apra_performers_declaration: '',
                apra_make_temporary_copy_for_examiner: 0,
                apra_make_temporary_copy_for_accompanist: 0,
                apra_is_terms_conditions_agreed: 0,
            },
            // apra_declaration:{
            //     apra_declaration_file: ''
            // },
            termsAgreed: true,
            rows:[],
            temp_types: [],
            types:[
                {header: 'Classical'},
                {name: 'List A', value: 'List A'},
                {name: 'List B', value: 'List B'},
                {name: 'List C', value: 'List C'},
                {name: 'List D', value: 'List D'},
                {name: 'List E', value: 'List E'},
                {name: 'Extra List', value: 'Extra List'},
                {name: 'Canon (Old Syllabus Piano Only)', value: 'Canon (Old Syllabus Piano Only)'},
                {name: 'Own Choice (Repertoire exams)', value: 'Own Choice (Repertoire exams)'},

                {header: 'For Leisure'},
                {name: 'List Piece', value: 'List Piece'},
                {name: 'Own Choice', value: 'Own Choice'},

                {header: 'Rockschool'},
                {name: 'Set Piece', value: 'Set Piece'},
                {name: 'Free Choice Piece', value: 'Free Choice Piece'},

                {header: 'P Plate Piano'},
                {name: 'Piece 1', value: 'Piece 1'},
                {name: 'Piece 2', value: 'Piece 2'},
                {name: 'Piece 3', value: 'Piece 3'},
            ],
            hasCandidatePlaylist: false,
            showPlaylistForm: false,
            candidatePlaylistId: null,
            edit: false,
            auralSightReadingValues: [
                {
                    name: 'Aural',
                    value: 'aural'
                },
                {
                    name: 'Sight-reading',
                    value: 'sight_reading'
                }
            ],
            requiresApraDeclaration: false,
            showApraField: false,
        }
    },
    computed:{
        currentDate(){
            const currentDate = new Date();

            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
        },
    },
    methods:{
        addFieldColumn(){
            this.fields.push({
                type:'',
                composer:'',
                publisher:'',
                title_of_piece:'',
            });
        },
        removeFieldColumn(index){
            this.fields.splice(index, 1);
        },
        buildPlayListData(){
            let type =[];
            let composer = [];
            let publisher = [];
            let title_of_piece = [];

            this.fields.forEach((field)=>{
                if(field.type)
                  type.push(field.type);
                if(field.composer)
                  composer.push(field.composer);
                if(field.title_of_piece)
                  title_of_piece.push(field.title_of_piece);
                if(field.publisher)
                  publisher.push(field.publisher);
            });

            this.candidate_playlist.type = type;
            this.candidate_playlist.composer = composer;
            this.candidate_playlist.publisher = publisher;
            this.candidate_playlist.title_of_piece = title_of_piece;
            this.candidate_playlist.exam_key = this.examKey;

            if(this.candidate_playlist.auralOrSightReading == 'aural'){
                this.candidate_playlist.has_aural_test = 1;
                this.candidate_playlist.has_sight_reading = 0;
            }
            if(this.candidate_playlist.auralOrSightReading == 'sight_reading'){
                this.candidate_playlist.has_aural_test = 0;
                this.candidate_playlist.has_sight_reading = 1;
            }
        },

        // convertToFormData() {            
        //     let formData = new FormData();
        //     for (let key in this.apra_declaration) {
        //         if (key === "apra_declaration_file" && this.apra_declaration[key] != null && this.apra_declaration[key] != undefined) {
        //             formData.append('file', this.apra_declaration[key]);
        //         }
        //     }
        //     return formData;
        // },

        saveCandidatePlaylist(){
            this.buildPlayListData();
            this.$v.$touch()
            
            if (this.$v.$error) {
                setTimeout(() => {
                this.$v.$reset()
                }, 60000);
            } else {
                if(this.requiresApraDeclaration && this.candidate_playlist.apra_include_copyright_declaration 
                    && this.candidate_playlist.apra_is_terms_conditions_agreed == 0){
                    this.termsAgreed = false;
                    return false;
                }

                this.loading = true;

                candidatePlayList
                .create(this.candidate_playlist)
                .then((response) => {
                    this.$snotify.success('Repertoire Playlist added');
                    this.loading = false;
                    this.getCandidatePlaylists();
                    this.candidate_playlist={
                        apra_include_copyright_declaration: 0,
                        apra_performers_declaration: '',
                        apra_make_temporary_copy_for_examiner: 0,
                        apra_make_temporary_copy_for_accompanist: 0,
                        apra_is_terms_conditions_agreed: 0
                    };
                    this.$v.$reset();
                    this.edit = false;
                    this.showApraField = false;
                    this.getCandidateSummary();
                })
                .catch((err) => {
                    this.$snotify.error('Oops we could not add a playlist.');
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
            }

        },
        // saveApraDeclaration(){
        //     let formData = this.convertToFormData();
        //     this.loading = true;

        //     candidatePlayList
        //     .saveApraDeclaration(this.candidatePlaylistId, formData)
        //     .then((response) => {          
        //         this.apra_declaration={
        //             apra_declaration_file: ''
        //         };
        //     })
        //     .catch((err) => {
        //         this.loading = false;
        //     })
        //     .finally( () => {
        //         this.loading = false;
        //     })
        // },
        getCandidateSummary(){
            this.loading = true;
            candidate
            .getCandidateSummary(this.examKey)
            .then((response) => {          
                this.candidate_summary = response.data.candidate;
                if(!this.edit && this.candidate_summary.requires_apra_declaration){
                    this.requiresApraDeclaration = true;
                    this.showApraField = true;
                }else{
                    this.requiresApraDeclaration = false;
                    this.showApraField = false;
                }
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally( () => {
                this.loading = false;
            })
        },
        buildDataForEdit(composer, title_of_piece, type, publisher){
            this.fields = [];

            let length = composer.length;
            for(var i=0; i<length; i++){
                this.fields.push(
                    {
                        type:type[i],
                        composer:composer[i],
                        publisher:publisher[i],
                        title_of_piece:title_of_piece[i]
                    }
                )
            }
            this.getTypes();
        },
        editCandidatePlaylist(){
            this.showPlaylistForm = !this.showPlaylistForm;
            this.edit = !this.edit;

            if(this.edit && this.requiresApraDeclaration){
                this.showApraField = true;
            }else{
                this.showApraField = false;
            }
        },
        viewPlaylistPdf(){
            candidatePlayList
            .viewPlaylistPdf(this.candidatePlaylistId);
        },
        getTypes(){
            let type = this.types;
            let selected = [];

            selected = this.fields.map((item) => {
                return item.type;
            });

            if(selected.length > 0){
                    type.forEach((x, i) => {
                        if(selected.includes(x.value)){
                            x.disable=true;
                        }else{
                            x.disable=false;
                        }
                    })
            }
            this.temp_types = type;
        },
        // viewApraDeclaration(file){
        //     window.open(file, '_blank');
        // },
        getCandidatePlaylists(){
            candidatePlayList
            .getByExamKey(this.examKey)
            .then((response) => {
                if(response.data.status == 'ERROR'){
                    this.hasCandidatePlaylist = false;
                    this.showPlaylistForm = true;
                    if(this.requiresApraDeclaration){
                        this.showApraField = true;
                    }
                }else{
                    this.hasCandidatePlaylist = true;
                    this.showPlaylistForm = false;
                    this.candidatePlaylistId = response.data.data.id;
                    // this.candidate_playlist.apra_declaration_file = response.data.data.apra_declaration_file_path;

                    this.candidate_playlist.has_aural_test = response.data.data.has_aural_test;
                    if(this.candidate_playlist.has_aural_test){
                        this.candidate_playlist.auralOrSightReading = 'aural';
                    }

                    this.candidate_playlist.has_sight_reading = response.data.data.has_sight_reading;
                    if(this.candidate_playlist.has_sight_reading){
                        this.candidate_playlist.auralOrSightReading = 'sight_reading';
                    }

                    this.candidate_playlist.apra_include_copyright_declaration = response.data.data.apra_include_copyright_declaration;
                    this.candidate_playlist.apra_performers_declaration = response.data.data.apra_performers_declaration;
                    this.candidate_playlist.apra_make_temporary_copy_for_examiner = response.data.data.apra_make_temporary_copy_for_examiner;
                    this.candidate_playlist.apra_make_temporary_copy_for_accompanist = response.data.data.apra_make_temporary_copy_for_accompanist;
                    this.candidate_playlist.apra_is_terms_conditions_agreed = response.data.data.apra_is_terms_conditions_agreed;
                    // this.candidate_playlist.apra_declaration_file_path = response.data.data.apra_declaration_file_path;
                    // this.apra_declaration.apra_declaration_file = response.data.data.apra_declaration_file;
                    this.candidate_playlist.formatted_created_date = response.data.data.formatted_created_date;

                    let composer = response.data.data.composer;
                    let title_of_piece = response.data.data.title_of_piece;
                    let type = response.data.data.type;
                    let publisher = response.data.data.publisher;

                    this.buildDataForEdit(composer, title_of_piece, type, publisher);
                }
            })
            .catch((err) => {
                this.hasCandidatePlaylist = false;
            })
            .finally(() => {
                // if(this.apra_declaration.apra_declaration_file){
                //     this.saveApraDeclaration();                
                // }
            });
        },
    },
    mounted(){
        this.examKey = this.$route.params.examKey;
        this.getCandidateSummary();
        this.getCandidatePlaylists();
        this.getTypes();
    }
}
</script>


<style scoped>
    .list-none{
        list-style:none;
    }
    .bg-yellow    
    {
        background-color: #fffada;
    }
</style>